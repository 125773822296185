import * as React from 'react';

import { Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setPageIndex } from '../../slices/pageIndexSlice';

import Footer from '../common/Footer';
import Courses from './Courses';
import TeachingInterests from './TeachingInterests';
import Testimonial from './Testimonial';

const TeachingPage = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setPageIndex(2));
  })

  return (
    <>
      <Container disableGutters maxWidth="lg">
        <TeachingInterests />
        <Courses />
        <Testimonial />
      </Container>
      <Footer />
    </>
  )
}

export default TeachingPage;
