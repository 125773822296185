import { configureStore } from '@reduxjs/toolkit';
import pageIndexSlice from './slices/pageIndexSlice';
import localeSlice from './slices/localeSlice';

export default configureStore({
  reducer: {
    pageIndex: pageIndexSlice,
    locale: localeSlice,
  },
})
