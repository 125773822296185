import * as React from 'react';

import { useQuery } from '@apollo/client';
import {
  Container,
  Divider,
} from '@mui/material';
import { useSelector } from 'react-redux';

import SectionHeader from '../common/SectionHeader';
import EducationItem from './EducationItem';
import { contentfulEducationQuery } from '../../utils/contentfulQueries';
import loc from '../../utils/loc';

const Education = () => {
  const locale = useSelector((state) => state.locale.value)

  const { loading, error, data } = useQuery(contentfulEducationQuery, {
    variables: { locale },
  });
  
  if (loading) return <></>;
  if (error) return <></>;
  const education = data.educationCollection.items;

  return (
    <Container sx={{ pt: 5, px: 5 }}>
      <SectionHeader title={loc("Education")} divider />
      {education.map((item, index) => {
        return (
          <div key={index}>
            {!!index && <Divider />}
            <EducationItem item={item} />
          </div>
        )
      })}
    </Container>
  )
}

export default Education;
