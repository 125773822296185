import * as React from 'react';

import { Link } from 'react-router-dom';

import {
  Container,
  styled,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import LocaleSwitch from '../common/LocaleSwitch';
import { setPageIndex } from '../../slices/pageIndexSlice';
import { setChinese, setEnglish } from '../../slices/localeSlice';
import loc from '../../utils/loc';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />
)(
  ({ theme }) => ({
    textTransform: "Uppercase",
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  }),
);

const NavigationBarForBiggerScreens = (props) => {
  const { pages, cvQuery } = props;
  const { loading, error, data } = cvQuery;

  const pageIndex = useSelector((state) => state.pageIndex.value)
  const isEnglishChecked = useSelector((state) => state.locale.value === 'en-US')
  const dispatch = useDispatch()

  const handlePageIndexChange = (_, newValue) => {
    if (newValue < pages.length) {
      dispatch(setPageIndex(newValue))
    }
  };

  const handleLocaleChange = (event) => {
    dispatch(event.target.checked ? setEnglish() : setChinese());
  };

  return (
    <Container maxWidth="lg" sx={{ display: { xs: 'none', md: 'flex' } }}>
      <div style={{ margin: "auto 0" }}>
        <Typography
          variant="h5"
          noWrap
          component={Link}
          to="/"
          onClick={() => dispatch(setPageIndex(0))}
          sx={{
            color: 'white',
            textDecoration: 'none',
          }}
        >
          {loc("name")}
        </Typography>
      </div>
      <Toolbar>
        <StyledTabs
          value={pageIndex}
          onChange={handlePageIndexChange}
          aria-label="styled tabs example"
          sx={{ py: 1, px: 3 }}
        >
          {pages.map((page) => (
            <StyledTab key={page} component={Link} to={`/${(page === "home") ? "" : page}`} label={loc(page)} />
          ))}
          {!(loading || error) && <StyledTab href={data.biographyCollection.items[0].cv.url} target="_blank" label={loc("cv")} sx={{ textTransform: "uppercase" }}/>}
        </StyledTabs>
      </Toolbar>
      <LocaleSwitch checked={isEnglishChecked} onChange={handleLocaleChange} sx={{ m: "auto 0 auto auto" }}/>
    </Container>
  )
}

export default NavigationBarForBiggerScreens;