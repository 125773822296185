import * as React from 'react';

import { useQuery } from '@apollo/client';
import {
  Chip,
  Container,
} from '@mui/material';
import { useSelector } from 'react-redux';

import SectionHeader from '../common/SectionHeader';
import { contentfulTeachingInterestsQuery } from '../../utils/contentfulQueries';
import loc from '../../utils/loc';

const TeachingInterests = () => {
  const locale = useSelector((state) => state.locale.value)
  
  const { loading, error, data } = useQuery(contentfulTeachingInterestsQuery, {
    variables: { locale },
  });
  
  if (loading) return <></>;
  if (error) return <></>;
  const { interests } = data.teachingInterestsCollection.items[0];

  return (
    <Container sx={{ pt: 5, px: 5 }}>
      <SectionHeader title={loc("Teaching Interests")} divider />
      {interests.map((interest, index) => {
        return <Chip key={index} color="primary" variant="outlined" size="large" label={interest} sx={{ margin: 0.5 }}/>
      })}
    </Container>
  )
}

export default TeachingInterests;
