import store from '../store';

const enUS = require("../locstrings/en-US.json");
const zhCN = require("../locstrings/zh-CN.json");

const loc = (stringKey, ...args) => {
    if (typeof stringKey !== 'string') {
        return stringKey;
    }
    
    console.log(stringKey);
    const locale = store.getState().locale.value;
    switch (locale) {
        case 'zh-CN':
            return (zhCN[stringKey] ?? stringKey).format(...args);
        default:
            return (enUS[stringKey] ?? stringKey).format(...args);
    }
}

export default loc;