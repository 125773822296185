import { createSlice } from '@reduxjs/toolkit'

export const pageIndexSlice = createSlice({
    name: 'pageIndex',
    initialState: {
      value: 0,
    },
    reducers: {
      setPageIndex: (state, action) => {
        state.value = action.payload
      },
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { setPageIndex } = pageIndexSlice.actions
  
  export default pageIndexSlice.reducer
