import * as React from 'react';
import { Helmet } from 'react-helmet';

import { useQuery } from '@apollo/client';
import {
  documentToReactComponents,
} from '@contentful/rich-text-react-renderer';
import {
  BLOCKS,
  INLINES,
} from '@contentful/rich-text-types';
import ArticleIcon from '@mui/icons-material/Article';
import {
  Button,
  Container,
  Link,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';

import SectionHeader from '../common/SectionHeader';
import { contentfulBiographyQuery } from '../../utils/contentfulQueries';
import loc from '../../utils/loc';

const Biography = () => {
  const locale = useSelector((state) => state.locale.value)
  
  const { loading, error, data } = useQuery(contentfulBiographyQuery, {
    variables: { locale },
  });
  
  if (loading) return <></>;
  if (error) return <></>;
  const { contentRaw, content, cv } = data.biographyCollection.items[0];

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: ({ data }, children) => (
        <Link
          href={data.uri}
          target="_blank"
          sx={{ textDecoration: "none" }}
        >
          {children}
        </Link>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => <Typography vairant="body" sx={{ fontSize: 17 }}>{children}</Typography>
    },
  };

  return (
    <>
      <Helmet>
        <meta name="description" content={contentRaw} />
      </Helmet>
      <Container sx={{ pt: 5, px: 5 }}>
        <SectionHeader title={loc('Biography')} />
        <Typography gutterBottom variant="body" component="div">
          {documentToReactComponents(content.json, options)}
        </Typography>
        <Button target="_blank" href={cv.url} variant="outlined" startIcon={<ArticleIcon />} sx={{ mt: 1 }}>
          {loc('View CV')}
        </Button>
      </Container>
    </>
  )
}

export default Biography;
