import { createSlice } from '@reduxjs/toolkit'

export const localeSlice = createSlice({
    name: 'locale',
    initialState: {
      value: 'en-US',
    },
    reducers: {
      setChinese: (state) => {
        state.value = 'zh-CN';
      },
      setEnglish: (state) => {
        state.value = 'en-US';
      },
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { setChinese, setEnglish } = localeSlice.actions
  
  export default localeSlice.reducer
