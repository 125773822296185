import * as React from 'react';

import { useQuery } from '@apollo/client';
import { Timeline } from '@mui/lab';
import { Container } from '@mui/material';
import { useSelector } from 'react-redux';

import AwardCard from './AwardCard';
import SectionHeader from '../common/SectionHeader';
import { contentfulAwardsQuery } from '../../utils/contentfulQueries';
import loc from '../../utils/loc';

const Awards = () => {
  const locale = useSelector((state) => state.locale.value)

  const { loading, error, data } = useQuery(contentfulAwardsQuery, {
    variables: { locale },
  });

  if (loading) return <></>;
  if (error) return <></>;
  const awards = data.honorsAwardsCollection.items;

  return (
    <Container sx={{ pt: 5, px: 5 }}>
      <SectionHeader title={loc("Honors & Awards")} divider />
      <Timeline position="alternate">
        {awards.map((award, index) => (
          <AwardCard key={index} award={award} />
        ))}
      </Timeline>
    </Container>
  )
}

export default Awards;
