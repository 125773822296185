import { gql } from '@apollo/client';

const contentfulAwardsQuery = gql`{
    honorsAwardsCollection(order: startTime_DESC) {
        items {
            name
            amount
            startTime
            endTime
            logo {
                url
            }
        }
    }
}`;

const contentfulBasicInfoQuery = gql`
    query BasicInfo($locale: String!) {
        basicInfoCollection(limit: 1, locale: $locale) {
            items {
                name
                jobTitle
                avatar {
                    url
                }
            }
        }
    }
`;

const contentfulBiographyQuery = gql`
    query Biography($locale: String!) {
        biographyCollection(limit: 1, locale: $locale) {
            items {
                contentRaw
                content {
                    json
                }
                cv {
                    url
                }
            }
        }
    }
`;

const contentfulContactsQuery = gql`{
    contactCollection(order: sys_publishedAt_ASC) {
        items {
            name
            url
            logo {
                url
            }
        }
    }
}`;

const contentfulCoursesQuery = gql`
    query Courses($locale: String!) {
        coursesCollection(order: sys_firstPublishedAt_ASC, locale: $locale) {
            items {
                name
                number
                level
                type
                institution {
                    name
                    logo {
                        fileName
                        url
                    }
                }
                times
                description
                picture {
                    fileName
                    url
                }
                syllabus {
                    url
                }
                assignment {
                    url
                }
                syllabusUrl
                assignmentUrl
            }
        }
    }
`;

const contentfulCVQuery = gql`{
    biographyCollection(limit: 1) {
        items {
            cv {
                url
            }
        }
    }
}`;

const contentfulEducationQuery = gql`
    query Education($locale: String!) {
        educationCollection(order: startTime_DESC, locale: $locale) {
            items {
                degree
                institution {
                    name
                    locationString
                    logo {
                        url
                    }
                }
                startTime
                endTime
            }
        }
    }
`;

const contentfulPublicationsQuery = gql`{
    publicationsCollection(order: time_DESC) {
        items {
            title
            status
            type
            time
            authorsCollection {
                items {
                    firstName
                    lastName
                }
            }
            journal
            url
            slides {
                url
            }
            videoUrl
        }
    }
}`;

const contentfulResearchInterestsQuery = gql`
    query ResearchInterests($locale: String!) {
        researchInterestCollection(order: sys_publishedVersion_ASC, locale: $locale) {
            items {
                name
                description
                picture {
                    url
                }
            }
        }
    }
`;

const contentfulSpecialtiesQuery = gql`
    query Specialties($locale: String!) {
        specialtiesCollection(limit: 1, locale: $locale) {
            items {
                specialties
            }
        }
    }
`;

const contentfulTeachingInterestsQuery = gql`
    query TeachingInterests($locale: String!) {
        teachingInterestsCollection(limit: 1, locale: $locale) {
            items {
                interests
            }
        }
    }
`;

const contentfulTestimonialQuery = gql`{
    testimonialCollection {
        items {
            comment
            course {
                name
                number
            }
            semester
        }
    }
}`;

export {
  contentfulAwardsQuery,
  contentfulBasicInfoQuery,
  contentfulBiographyQuery,
  contentfulContactsQuery,
  contentfulCoursesQuery,
  contentfulCVQuery,
  contentfulEducationQuery,
  contentfulPublicationsQuery,
  contentfulResearchInterestsQuery,
  contentfulSpecialtiesQuery,
  contentfulTeachingInterestsQuery,
  contentfulTestimonialQuery,
};
