import * as React from 'react';

import { Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setPageIndex } from '../../slices/pageIndexSlice';

import Footer from '../common/Footer';
import Publications from './Publications';
import ResearchInterests from './ResearchInterests';

const ResearchPage = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setPageIndex(1));
  })

  return (
    <>
      <Container disableGutters maxWidth="lg">
        <ResearchInterests />
        <Publications />
      </Container>
      <Footer />
    </>
  )
}

export default ResearchPage;
