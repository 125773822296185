import * as React from 'react';

import { useQuery } from '@apollo/client';
import {
  Container,
  Grid,
} from '@mui/material';
import { useSelector } from 'react-redux';

import SectionHeader from '../common/SectionHeader';
import ResearchInterestCard from './ResearchInterestCard';
import { contentfulResearchInterestsQuery } from '../../utils/contentfulQueries';
import loc from '../../utils/loc';

const ResearchInterests = () => {
  const locale = useSelector((state) => state.locale.value)

  const { loading, error, data } = useQuery(contentfulResearchInterestsQuery, {
    variables: { locale },
  });
  
  if (loading) return <></>;
  if (error) return <></>;
  const interests = data.researchInterestCollection.items;

  return (
    <Container sx={{ pt: 5, px: 5 }}>
      <SectionHeader title={loc("Research Interests")} divider />
      <Grid container spacing={2}>
        {interests.map((interest, index) => <ResearchInterestCard key={index} interest={interest} />)}
      </Grid>
    </Container>
  )
}

export default ResearchInterests;
