import * as React from 'react';

import {
  Button,
  Typography,
} from '@mui/material';

import loc from '../../utils/loc';

const SelectStatusBar = (props) => {
  const { clearAllCallback, count } = props;

  return (
    <div style={{ display: "flex" }}>
      <Button variant="outlined" size="small" onClick={clearAllCallback} sx={{ m: 1 }}>
        {loc("Clear All").toUpperCase()}
      </Button>
      <Typography variant="body3" sx={{ my: "auto", ml: 4 }}>
        {count === 1 ? loc("SingleResultStatusString") : loc("MultipleResultsStatusString", count)}
      </Typography>
    </div>
  );
};

export default SelectStatusBar;
