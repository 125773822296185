import * as React from 'react';

import { Link } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import {
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import LocaleSwitch from '../common/LocaleSwitch';
import { setPageIndex } from '../../slices/pageIndexSlice';
import { setChinese, setEnglish } from '../../slices/localeSlice';
import loc from '../../utils/loc';

const NavigationBarForSmallerScreens = (props) => {
    const { pages, cvQuery } = props;
    const { loading, error, data } = cvQuery;

    const isEnglishChecked = useSelector((state) => state.locale.value === 'en-US')
    const dispatch = useDispatch()
  
    const handleLocaleChange = (event) => {
      dispatch(event.target.checked ? setEnglish() : setChinese());
    };
  
    const [anchorElNav, setAnchorElNav] = React.useState(null);
  
    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
  
  return (
    <Toolbar sx={{ display: { xs: 'flex', md: 'none' } }}>
      <IconButton
        size="large"
        onClick={handleOpenNavMenu}
        >
        <MenuIcon sx={{ color: "#fff" }}/>
      </IconButton>
      <Typography
        variant="h6"
        noWrap
        component={Link}
        to="/"
        onClick={() => dispatch(setPageIndex(0))}
        sx={{
          display: 'inline-block',
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-50%)",
          color: '#fff',
          textDecoration: 'none',
        }}
      >
        {loc("name")}
      </Typography>
      <LocaleSwitch checked={isEnglishChecked} onChange={handleLocaleChange} sx={{ m: "auto 0 auto auto" }}/>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
            display: { xs: 'block', md: 'none' },
        }}
        >
        {pages.map((page, index) => (
          <MenuItem
            key={page}
            component={Link}
            to={page === "home" ? "" : `/${page}`}
            onClick={handleCloseNavMenu}
            sx={{ textTransform: "capitalize" }}>
              {loc(page)}
          </MenuItem>
        ))}
        {!(loading || error) && <MenuItem
          component="a"
          href={data.biographyCollection.items[0].cv.url}
          target="_blank"
          onClick={handleCloseNavMenu}
          sx={{ textTransform: "uppercase" }}>
            {loc("cv")}
          </MenuItem>}
      </Menu>
    </Toolbar>
  )
}

export default NavigationBarForSmallerScreens;
